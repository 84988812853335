import React from 'react'
import './Kontakt.css'
import Article from '../../components/Article.js'

export default function Kontakt() {
  return (
    <div className='kontakt-container'>
      <br></br>
      <br></br>
      <Article header='Stowarzyszenie Ognisko Sportu Niepełnosprawnych "Alfa Sport Toruń"'></Article>
      <Article paragraph='E-mail: seweryn.smorga@mosir.torun.pl'></Article>
      <Article paragraph='Telefon: 606612583'></Article>
      <br></br>
      <Article paragraph='Czekamy na was 😊'></Article>
      <br></br>
    </div>
  )
}
