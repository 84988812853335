import React from 'react'
import Article from '../../components/Article'

function Harmonogram() {
  return (
    <div>
      <Article paragraph='Biegi (60m, 100m, 400m) - godziny 10.00-12.00'></Article>
      <Article paragraph='Skok w dal - godziny 10.00-12.00'></Article>
      <Article paragraph='Rzut piłką lekarską – godziny 10.00-13.00'></Article>
      <Article paragraph='Wiatrówka - godziny 10.00-13.00'></Article>
      <Article paragraph='Wioślarstwo - godziny 10.00-13.00'></Article>
      <Article paragraph='Rower stacjonarny - godziny 10.00-13.00'></Article>
      <Article paragraph='Piłka nożna - godziny 13.00-14.00'></Article>
      <Article paragraph='Zestaw konkurencji - godziny 10.00-14.00'></Article>
      <Article paragraph='Boule - godziny 10.00-13.30'></Article>
      <Article paragraph='Nordic walking - godziny 13.00-14.00'></Article>
      <Article paragraph='Tenis stołowy - od godziny 10.30'></Article>
      <Article paragraph='Konkurencje siłowe (sztanga) - 10.30-11.30'></Article>
      <Article paragraph='Wspinaczka - godziny 11.00-12.00'></Article>
      <Article paragraph='Pływanie - godziny 12.30-14.00'></Article>
    </div>
  )
}

export default Harmonogram