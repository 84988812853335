import React from 'react'
import './Wolontariat.css'
import Article from '../../components/Article.js'

export default function Wolontariat() {
  return (
    <div className='wolontariat-container'>
        <Article paragraph='
        To już XIII Edycja Toruńskiej Olimpiady Osób Niepełnosprawnych i nieustannie podtrzymujemy bezpłatność całej imprezy dla uczestników. Zainteresowanie rośnie każdego roku — w tym roku przy tej szczęśliwej 13-stce spodziewamy się jeszcze większego towarzystwa do wspólnej rywalizacji sportowej, a do tego potrzebujemy pomocnych dłoni. Będziemy wdzięczni za każdą pomoc wolontariacką. Aby zgłosić swoje chęci do pomocy, można skontaktować się z nami telefonicznie i mailowo:'>
        </Article>
        <Article paragraph='E-mail: seweryn.smorga@mosir.torun.pl'></Article>
        <Article paragraph='Telefon: 606612583'></Article>
        <Article paragraph='Czekamy na was 😊'></Article>
        <br></br>
        <br></br>
    </div>
  )
}
