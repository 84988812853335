import React from 'react'
import './Blad.css'

export default function Blad() {
  return (
    <div className='blad-container'>
      <h1>BŁĄD!</h1>
      <p>Podczas próby wysłania formularza wystąpił błąd.</p>
    </div>
  )
}
