import React from 'react'
import './Sukces.css'
import PdfIco from '../../images/pdfIco.png'

export default function Sukces() {
  return (
    <div className='sukces-container'>
      <h1>Sukces!</h1>
      <p>Dziękujemy za wysłanie formularza.</p>
      <a className='pdfDownload' href={'/files/Regulamin_Olimpiady_2024.pdf'} download><img src={PdfIco} alt='pdf-icon'></img>Pobierz regulamin</a>
      <a className='pdfDownload' href={'/files/Instrukcja_2024.pdf'} download><img src={PdfIco} alt='pdf-icon'></img>Pobierz instrukcje</a>
    </div>
  )
}
