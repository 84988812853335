import React from 'react'
import './Regulamin.css'

export default function Regulamin() {
  return (
    <div className='regulamin-container'>
      <h1>Regulamin XIII Toruńskiej Olimpiady Osób Niepełnosprawnych | 8 czerwca 2024 |</h1>
      <ol>
        <li><b>Miejsce wydarzenia:</b></li>
        <ul>
          <li>Stadion Miejski — wjazd ulicą Osmańskiego przez główną bramę stadionu na parking obiektu (znajduje się tuż za Lodowiskiem Tor-Tor, ul. Bema 23/29)</li>
          <li>Centrum wspinaczkowe GATO (budynek obok Lodowiska Tor-Tor)</li>
          <li>Mini Aquapark, ul. Hallera 79 (Przejazd wynajętym przez organizatora autobusem)</li>
        </ul>
        <li><b>Organizatorzy:</b></li>
        <ul>
          <li>Stowarzyszenie Ognisko Sportu Niepełnosprawnych „Alfa Sport Toruń”</li>
          <li>Miejski Ośrodek Sportu i Rekreacji</li>
        </ul>
        <li><b>Wspierają:</b></li>
        <ul>
          <li>Urząd Miasta Torunia</li>
          <li>Urząd Marszałkowski Województwa Kujawsko-Pomorskiego</li>
          <li>PGE Toruń</li>
        </ul>
        <li><b>Zapisy i zasady przesyłania zgłoszeń</b></li>
        <ul>
          <li>W mailach z zaproszeniami będą przesłane formularze do pobrania i wypełnienia.</li>
          <li>Po wypełnieniu proszę odesłać wypełnione formularze na mail: <a href="mailto: seweryn.smorga@mosir.torun.pl">seweryn.smorga@mosir.torun.pl</a></li>
        </ul>
        <h1>UWAGA!</h1>
        <p>W przypadku niepełnego uzupełnienia formularza nie zostanie on przyjęty. Prosimy również w przypadkach konkurencji na odległość wpisywać na jakie dokładnie odległości uczestnicy się zapisują. W przypadku braku informacji o dystansie/odległości, w jakim chce uczestniczyć zawodnik, np. w biegach na dystans, uczestnik nie zostanie do niej przypisany. Liczba uczestników danej dyscypliny jest ograniczona, decyduje kolejność zgłoszeń. Z powodu różnych lokalizacji dyscyplin jednoczesny wybór tych konkurencji jest niemożliwy: PIŁKA NOŻNA i PŁYWANIE.<br></br><br></br><b>W mailu bądź zgłoszeniu prosimy o podanie sumy opiekunów, którzy przyjadą z uczestnikami. Ponadto prosimy o zapisywanie wszystkich informacji w zgłoszeniach wielkimi literami. Limit 200 osób. Zgłoszenia przyjmujemy drogą elektroniczną do dnia 12 maja 2023 (piątek). Zgłoszenia, które wpłyną po terminie nie zostaną przyjęte. Ze względu na charakter imprezy przy składaniu zgłoszeń prosimy o potwierdzenie przybycia.</b></p>
        <h1>UWAGA!</h1>
        <p><b>Medale zostaną wydane opiekunom zaraz po zgłoszeniu się po numery startowe. Ponadto prosimy o to, aby numery startowe były naklejone na ubrania tak, aby cały czas były widoczne (jeżeli mamy na sobie bluzę/kurtkę, którą cały czas będziemy mieć na sobie, to przyklejamy na nią numer startowy, a nie na koszulkę pod spodem na plecach). Osoby, które nie mają numeru startowego nie otrzymują medali. Nikomu poza głównym opiekunem grup nie zostaną wydane. Takiej samej zasadzie podlega wydawanie drożdżówek i mini pizzy – przekazujemy je opiekunom na początku rozgrywek w torbach.</b></p>
        <li>Planowany odjazd autobusu dla uczestników, którzy wybrali konkurencje w pływaniu: O godzinie 12:30 wyjeżdżamy ze Stadionu Miejskiego. Powrót na godzinę 14:30 na koncert.</li>
        <li><b>PLANOWANE DYSCYPLINY:</b></li>
        <h1>UWAGA!</h1>
        <p><b>W konkurencjach lekkoatletycznych i w nordic walking liczymy czas trzech pierwszych osób, ponieważ dla nich są wydawane dyplomy za zdobycie miejsca. We wszystkich konkurencjach wydawane są dyplomy za zajęcie I, II i III miejsca.</b></p>
        <br></br>
        <ul>
          <li>
            <b>LEKKOATLETYKA</b> – <u>Stadion Miejski</u> (10.00-14.00):
            <ul>
              <li>
                <b>BIEGI – <u>Czynne w godzinach 10.00-13.00</u></b>
                <ul>
                  <li>60 Metrów ( Klasy I-III)</li>
                  <li>100 Metrów</li>
                  <li>400 Metrów</li>
                  <li>Wózki inwalidzkie ze startu wspólnego – <b>dystans 400 m</b></li>
                  <li>Wózki (klasy I-III)  – <b>dystans 100 m</b></li>
                </ul>
              </li>
              <li><b>BIEG DLA PRZEDSZKOLAKÓW</b>–  bieg z rodzicem na dystans 100 m</li>
              <li><b>SKOK W DAL</b> (wyznaczona strefa): <b>3 podejścia</b></li>
              <li><b>RZUT PIŁKĄ LEKARSKĄ</b> (1kg – klasy I-III, 2kg – Klasy IV-VI, pozostali – tylko 3 kg): <b>3 podejścia, technika dowolna.</b></li>
            </ul>
          </li>
          <li><b>STRZAŁ Z WIATRÓWKI DO CELU</b> – <u>Stadion Miejski</u> (10.00-14.00)</li>
          <li><b>WIOŚLARSTWO STACJONARNE</b> – <u>Stadion Miejski</u> (10.00-14.00): <b>Do pokonania jak najdłuższy dystans w czasie 1 minuty.</b></li>
          <li><b>ROWERY STACJONARNE</b> – <u>Stadion Miejski</u> (10.00-14.00): <b>Do pokonania jak najdłuższy dystans w czasie 1 minuty.</b></li>
          <li><b>PIŁKA NOŻNA</b> – <u>Stadion Miejski</u>: <b>Szóstki piłkarskie (drużyny)</b></li>
          <li><b>ZESTAW KONKURENCJI</b> skierowany do tych szczególnie wrażliwych osób, które nie mogą brać udziału w oficjalnych treningach i zawodach Olimpiad Specjalnych ze względu na współistniejącą z niepełnosprawnością intelektualną niepełnosprawnością fizyczną o bardzo szerokim zakresie. – <u>Stadion Miejski</u> (10.00-14.00)</li>
          <li><b>BOULE</b> – <u>Stadion Miejski</u> (10.00-14.00)</li>
          <li><b>NORDIC WALKING</b> – Stadion Miejski (13.00-14.00): <b>Do pokonania 400 m (1 okrążenie)</b></li>
          <li><b>TENIS STOŁOWY (TURNIEJ)</b> – <u>Lodowisko Tor-Tor</u> (10.00-14.00)</li>
          <li><b>KONKURENCJE SIŁOWE</b> – <u>Lodowisko Tor-Tor</u> (siłownia) (10.30-11.30): <b>Wyciskanie sztangi leżąc – 3 podejścia.</b></li>
          <li><b>WSPINACZKA</b> – <u>Centrum Wspinaczkowe Gato</u> (10.00-12.00)</li>
          <li>
            <b>PŁYWANIE</b> – <u>Mini AquaPark ul. Hallera 79</u> (13.00 – 14.30):
            <ul>
              <li>Dystans 25 m – styl dowolny</li>
              <li>Dystans 50 m – styl dowolny</li>
            </ul>
          </li>
          <li>
            <b>Zabawy w wodzie:</b>
            <ul>
              <li>Pływanie z deską (Dystans: 12 m, 25 m, 50 m)</li>
              <li>Pływanie z makaronem (Dystans: 12 m, 25 m, 50 m)</li>
              <li>Wyławianie krążków na czas</li>
            </ul>
          </li>
          <li><b><u>Od godz. 14.30 do 16.00 odbędzie się koncert i biesiadna zabawa (Stadion Miejski).</u></b></li>
        </ul>
        <li>
          <b>UCZESTNICTWO</b>
          <p>Prawo startu mają osoby niepełnosprawne bez względu na wiek, płeć, stopień i rodzaj niepełnosprawności.</p>
          <p>Rozgrywki prowadzone będą z podziałem na wiek, płeć, stopień i rodzaj niepełnosprawności z orzeczeniem lekarskim:</p>
          <ul>
            <li>sprawne i niesprawne ruchowo (poruszające się wózkiem inwalidzkim, o kulach)</li>
            <li>niepełnosprawne intelektualnie w stopniu lekkim, umiarkowanym i znacznym</li>
            <li>o niepełnosprawności sprzężonej ruchowej i intelektualnej</li>
          </ul>
          <p><u>Osoby, które do dnia 2 czerwca nie ukończą 18 roku życia, zobowiązane są do posiadania pisemnej zgody opiekunów prawnych na uczestnictwo.</u></p>
          <p>Prosimy o dokładne sprecyzowanie stopnia i rodzaju niepełnosprawności oraz wieku.</p>
          <p><b>Przykład: Seweryn Smorga, lat 30, I grupa inwalidzka, znaczny stopień niepełnosprawności, 4 kończynowe mózgowe porażenie dziecięce, poruszający się o kulach.</b></p>
        </li>
      </ol>
    </div>
  )
}
