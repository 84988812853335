import React from 'react'
import './Wsparcie.css'
import Article from '../../components/Article'

export default function Wsparcie() {
  return (
    <div className='wsparcie-container'>
      <Article paragraph='Jeśli jesteś zainteresowany dołożeniem cegiełki wsparcia na XIII Toruńską Olimpiadę Osób Niepełnosprawnych, zapraszamy do kontaktu:'></Article>
      <Article paragraph='E-mail: seweryn.smorga@mosir.torun.pl'></Article>
      <Article paragraph='Telefon: 606612583'></Article>
      <Article paragraph="Odwdzięczymy się jak tylko możemy: stanowiskiem dla ciebie, możliwością zamieszczenia baneru, roll up'u, rozdawania ulotek... Jedyne, co może nas ograniczyć to wyobraźnia 😊"></Article>
      <br></br>
      <br></br>
    </div>
  )
}
