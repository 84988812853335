import React from 'react'
import './Banner.css'
import banner from '../images/medal-hi-res.png'

function Banner() {
  return (
    <div className='banner-container'>
      <img className='banner' src={banner} alt='XII_Torunska_Olimpiada_Osob_Niepelnosprawnych_Logo'></img>
    </div>
  )
}

export default Banner
