import React from 'react'
import Article from '../../components/Article.js';
import Banner from '../../components/Banner.js';
import NavBar from '../../components/NavBar.js';
import './StronaGlowna.css';

function StronaGlowna() {
  return (
    <div>
      <Article paragraph={'Środki transportu uczestników mogą parkować na koronie Stadionu przy parkowaniu prosimy o zostawienie miejsca do przejazdu pojazdów pozostałych grup uczestniczących w Olimpiadzie.'}></Article>
      <Article paragraph={'Niskopodłogowy autobus, który zawozi uczestników na dyscyplinę pływania odjeżdża o godzinie 12:30. Podjedzie on na parking przy koronie Stadionu. Kierowca wyjeżdża równo o godzinie 12.30, wobec czego osoby, które się spóźnią, nie będą mogły wziąć udziału w dyscyplinie pływania. Powrót z basenu będzie na godzinę 14:30 na Stadion Miejski.'}></Article>
      <br></br><br></br>
      <Article paragraph={'XIII Toruńska Olimpiada odbywa się na Stadionie Miejskim, z wyjątkiem: Wspinaczki jest ona w Centrum Wspinaczkowym GATO, ul. Gen. Józefa Bema 23/29. Znajduje się on obok Lodowiska Tor-Tor.'}></Article>
      <div className='map-container'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2400.0924197461923!2d18.58882007656636!3d53.01870157219609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470334fed6a9226f%3A0xaed84586dcde454b!2sCentrum%20Wspinaczkowe%20GATO!5e0!3m2!1spl!2spl!4v1713607239520!5m2!1spl!2spl" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <br></br><br></br>
      <Article paragraph={'Tenisa - rozgrywki są na terenie Lodowiska Tor-Tor, ul. Gen. Józefa Bema 23/29'}></Article>
      <div className='map-container'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2400.0665475345386!2d18.589369476566414!3d53.019166672196235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470334ff2a7ed629%3A0xb2439c1834fbd3af!2sLodowisko%20Tor-Tor%20im.%20J%C3%B3zefa%20Stogowskiego%20w%20Toruniu!5e0!3m2!1spl!2spl!4v1713607446418!5m2!1spl!2spl" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <br></br><br></br>
      <Article paragraph={'Przy zamkniętych kasach (tuż przy ul. Osmańskiego) będzie wyznaczona przez nas osoba, która pokieruje grupy do budynku na dyscypliny wspinaczki oraz tenisa. Prosimy chodzić grupami, a nie pojedynczo, ponieważ to ułatwi przeprowadzenie zawodów.'}></Article>
      <Article paragraph={'Prosimy o zachowanie szczególnej uwagi na wywoływane nazwiska do dyscyplin, które wymagają puszczenia zawodników w seriach.'}></Article>
      <Article paragraph={'Prosimy o zachowanie szczególnej uwagi na czas, w którym można udać się na daną dyscyplinę.'}></Article>
    </div>
  )
}

export default StronaGlowna
