import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar.js';
import Banner from './components/Banner.js';
import Footer from './components/Footer.js';
import StronaGlowna from './pages/StronaGlowna/StronaGlowna';
import Harmonogram from './pages/Harmonogram/Harmonogram';
import Kontakt from './pages/Kontakt/Kontakt.js';
import Fromularz from './pages/Formularz/FormularzZgloszeniowy.js';
import Countdown from './components/Countdown.js';
import Sukces from './pages/Sukces/Sukces.js';
import Blad from './pages/Blad/Blad.js';
import Wolontariat from './pages/Wolontariat/Wolontariat.js';
import Wsparcie from './pages/Wsparcie/Wsparcie.js';
import CzasMinal from './pages/CzasMinal/CzasMinal.js';

const targetCountdownDane = new Date("2024-05-20T21:00:00")

function App() {
  return (
    <div className="App">
      <div className='content'>
        <Banner></Banner>
        <Countdown targetDate={targetCountdownDane}></Countdown>
        <NavBar></NavBar>
        <Routes>
          <Route path='/' element={<StronaGlowna></StronaGlowna>}></Route>
          <Route path='/pages/Harmonogram/Harmonogram.js' element={<Harmonogram></Harmonogram>}></Route>
          <Route path='/pages/Formularz/FormularzZgloszeniowy.js' element={<Fromularz></Fromularz>}></Route>
          <Route path='/pages/Kontakt/Kontakt.js' element={<Kontakt></Kontakt>}></Route>
          <Route path='/pages/Wolontariat/Wolontariat.js' element={<Wolontariat></Wolontariat>}></Route>
          <Route path='/pages/Wsparcie/Wsparcie.js' element={<Wsparcie></Wsparcie>}></Route>
          <Route path='/pages/Sukces/Sukces.js' element={<Sukces></Sukces>}></Route>
          <Route path='/pages/Blad/Blad.js' element={<Blad></Blad>}></Route>
        </Routes>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
