import React, { useState } from 'react';
import './FormularzZgloszeniowy.css'
import axios from 'axios'
import Regulamin from '../../components/Regulamin';
import { useNavigate } from "react-router-dom";
import PdfIco from '../../images/pdfIco.png'
import DocxIco from '../../images/docxIco.png'

const FormularzZgloszeniowy = () => {
  const navigate = useNavigate();
  const POST = '/requestHandler.php';
  const [formData, setFormData] = useState({
    osoby: [],
    organizacja: '',
    opiekunowie: []
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddOsoba = (e) => {
    e.preventDefault();

    const maxOsobyCount = 30

    if(formData.osoby.length < maxOsobyCount){
      setFormData({
        ...formData,
        osoby: [
          ...formData.osoby,
          { imie: '', nazwisko: '', plec: '', grupaWiekowa: '', poruszaSiePrzyPomocy: '', stopienNiepelnosprawnosci: '', wybraneNiepelnosprawnosci: [], wybraneDyscypliny: []}
        ]
      });
    }
  };

  const handleOsobaInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedOsoby = [...formData.osoby];
    updatedOsoby[index][name] = value;
    setFormData({ ...formData, osoby: updatedOsoby});
  }

  const handleRemoveOsoba = (e, index) => {
    e.preventDefault();

    const updatedOsoby = [...formData.osoby];
    updatedOsoby.splice(index, 1);
    setFormData({ ...formData, osoby: updatedOsoby});
  }

  const handleAddOpiekun = (e) => {
    e.preventDefault();

    const maxOpiekuniCount = 30;

    if (formData.opiekunowie.length < maxOpiekuniCount){
      setFormData({
        ...formData,
        opiekunowie: [
          ...formData.opiekunowie,
          { imie: '', nazwisko: '', numerTelefonu: '' }
        ]
      });
    }
  };

  const handleOpiekunInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedOpiekunowie = [...formData.opiekunowie];
    updatedOpiekunowie[index][name] = value;
    setFormData({ ...formData, opiekunowie: updatedOpiekunowie });
  };

  const handleRemoveOpiekun = (e, index) => {
    e.preventDefault();

    const updatedOpiekunowie = [...formData.opiekunowie];
    updatedOpiekunowie.splice(index, 1);
    setFormData({ ...formData, opiekunowie: updatedOpiekunowie });
  };

  const handleDyscyplinySelectionChanged = (e, osobaIndex) => {
    const selectedDyscyplina = e.target.value;

    // Sprawdź, czy checkbox jest zaznaczony
    if (e.target.checked) {
      // Jeśli jest zaznaczony, dodaj wartość do listy wybraneDyscypliny
      setFormData((formData) => {
        const updatedOsoby = [...formData.osoby];
        const updatedWybraneDyscypliny = [...updatedOsoby[osobaIndex].wybraneDyscypliny, selectedDyscyplina];
        updatedOsoby[osobaIndex] = { ...updatedOsoby[osobaIndex], wybraneDyscypliny: updatedWybraneDyscypliny };
        return { ...formData, osoby: updatedOsoby };
      });
    } else {
      // Jeśli jest odznaczony, usuń wartość z listy wybraneDyscypliny
      setFormData((formData) => {
        const updatedOsoby = [...formData.osoby];
        const updatedWybraneDyscypliny = updatedOsoby[osobaIndex].wybraneDyscypliny.filter(dyscyplina => dyscyplina !== selectedDyscyplina);
        updatedOsoby[osobaIndex] = { ...updatedOsoby[osobaIndex], wybraneDyscypliny: updatedWybraneDyscypliny };
        return { ...formData, osoby: updatedOsoby };
      });
    }
  }

  const handleRodzajNiepelnosprawnosciSelectionChanged = (e, osobaIndex) =>{
    const selectedRodzajNiepelnosprawnosci = e.target.value;

    // Sprawdź, czy checkbox jest zaznaczony
    if (e.target.checked) {

      // Jeśli jest zaznaczony, dodaj wartość do listy wybraneNiepelnosprawnosci
      setFormData((formData) => {
          const updatedOsoby = [...formData.osoby];
          const updatedWybraneNiepelnosprawnosci = [...updatedOsoby[osobaIndex].wybraneNiepelnosprawnosci, selectedRodzajNiepelnosprawnosci];
          updatedOsoby[osobaIndex] = { ...updatedOsoby[osobaIndex], wybraneNiepelnosprawnosci: updatedWybraneNiepelnosprawnosci };
          return { ...formData, osoby: updatedOsoby };
        });
      } else {
        // Jeśli jest odznaczony, usuń wartość z listy wybraneNiepelnosprawnosci
        setFormData((formData) => {
          const updatedOsoby = [...formData.osoby];
          const updatedWybraneNiepelnosprawnosci = updatedOsoby[osobaIndex].wybraneNiepelnosprawnosci.filter(niep => niep !== selectedRodzajNiepelnosprawnosci);
          updatedOsoby[osobaIndex] = { ...updatedOsoby[osobaIndex], wybraneNiepelnosprawnosci: updatedWybraneNiepelnosprawnosci };
          return { ...formData, osoby: updatedOsoby };
      });
    }
  }

  function isValueEmpty(element){
    if(element != null && element.value === '') return true
    else return false
  }

  function validateDyscypliny(tablica, ...szukaneStringi) {
    let liczbaWystapien = 0;
    for (const element of tablica) {
      if (szukaneStringi.includes(element)) {
          liczbaWystapien++;
          // Jeśli liczba wystąpień przekracza 1, zwracamy fałsz
          if (liczbaWystapien > 1) {
              return false;
          }
      }
  }

  // Zwracamy prawdę, jeśli liczba wystąpień każdego stringa nie przekracza 1
  return true;
}

  const handleSubmit = (e) => {
    e.preventDefault();

    const imieElements = document.querySelectorAll('.inputImie'); 
    const nazwiskoElements = document.querySelectorAll('.inputNazwisko');
    const plecElements = document.querySelectorAll('.inputPlec');
    const organizacjaElements = document.querySelectorAll('.inputOrganizacja');
    const grupaWiekowaElements = document.querySelectorAll('.inputGrupaWiekowa');
    const stopienNiepelnosprawnosciElements = document.querySelectorAll('.inputStopienNiepelnosprawnosci');
    const poruszaSiePrzyPomocyElements = document.querySelectorAll('.inputPoruszaSiePrzyPomocy');

    if(formData.osoby.length == 0){
      alert('Należy podać przynajmniej jednego uczestnika!');
      return;
    }

    let isFormComplete = true;
    imieElements.forEach(e => {
      if(isValueEmpty(e)) { 
        e.style.border = '3px solid red'; 
        isFormComplete = false
      }
      else { e.style.border = '2px solid black' }
    })
    nazwiskoElements.forEach(e =>{
      if(isValueEmpty(e)){ 
        e.style.border = '3px solid red'; 
        isFormComplete = false 
      }
      else { e.style.border = '2px solid black' }
    })
    plecElements.forEach(e => {
      if(isValueEmpty(e)){
        e.style.border = '3px solid red';
        isFormComplete = false;
      }
      else{ e.style.border = '2px solid black' }
    })
    organizacjaElements.forEach(e => {
      if(isValueEmpty(e)){ 
        e.value = "PRYWATNIE";
      }
    })
    grupaWiekowaElements.forEach(e => {
      if(isValueEmpty(e)){ 
        e.style.border = '3px solid red'; 
        isFormComplete = false 
      }
      else { e.style.border = '2px solid black' }
    })
    stopienNiepelnosprawnosciElements.forEach(e => {
      if(isValueEmpty(e)){ 
        e.style.border = '3px solid red'; 
        isFormComplete = false 
      }
      else { e.style.border = '2px solid black' }
    })
    poruszaSiePrzyPomocyElements.forEach(e => {
      if(isValueEmpty(e)){
        e.style.border = '3px solid red';
        isFormComplete = false;
      }
      else{ e.style.border = '2px solid black' }
    })
      // if(formData != null && formData.wybraneDyscypliny != null && formData.wybraneDyscypliny.length == 0){
      //   dyscyplinyContainer.style.border = '3px solid red';
      //   isFormComplete = false;
      // }
      // else if (formData != null && formData.wybraneDyscypliny != null
      //   && !validateDyscypliny(formData.wybraneDyscypliny, "PIŁKA_NOŻNA", "PŁYWANIE_25M", "PŁYWANIE_50M", "ZABAWY_W_WODZIE")
      // ){
      //   let warningParagraph = document.getElementById("pWarning");
      //   warningParagraph.classList.add('blinkWarning');
      //   setTimeout(() => {
      //     warningParagraph.classList.remove('blinkWarning');
      //   }, 2000);
      //   return;
      // }
      // else{ dyscyplinyContainer.style.border = '2px solid black'; }

    if(isFormComplete == false){
      organizacjaElements[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
      return; 
    }

    const checkbox = document.getElementById('chkRegulamin');
    const checkmark = document.getElementById('checkmarkRegulamin');
    const paragraph = document.getElementById('pRegulamin');

    if(!checkbox.checked){
      paragraph.style.fontStyle = 'italic';
      paragraph.style.fontWeight = 'bold';
      paragraph.style.textDecoration = 'underline';
      checkmark.classList.add('blinkWarning');
      paragraph.classList.add('blinkWarning');

      checkmark.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });

      setTimeout(() => {
        checkmark.classList.remove('blinkWarning');
        paragraph.classList.remove('blinkWarning');
      }, 2000);

      return;
    }

    // czyszczenie pustych opiekunów
    formData.opiekunowie = formData.opiekunowie.filter(o => o.imie !== "" && o.nazwisko !== "");
    
    axios.post(POST, formData)
      .then(response => {
        console.log(response);
        navigate("/pages/Sukces/Sukces.js");
      })
      .catch(error => {
        console.log(error);
        navigate("/pages/Blad/Blad.js");
      });
  };

  return (
    <div className='formularz-zgloszeniowy-container'>
        <div>
          <Regulamin></Regulamin>
          <a className='pdfDownload' href={'/files/Regulamin_Olimpiady_2024.pdf'} download><img src={PdfIco} alt='pdf-icon'></img>Pobierz regulamin</a>
          <a className='pdfDownload' href={'/files/Instrukcja_2024.pdf'} download><img src={PdfIco} alt='pdf-icon'></img>Pobierz instrukcje</a>
          <br></br>
        </div>
        <div className='regulamin-confirmation-container'>
          <label htmlFor="chkRegulamin" className='regulamin-confirmation-container-label'>
            <p id='pRegulamin'>*Potwierdzam, że przeczytałem/am regulamin</p>
            <input type="checkbox" id="chkRegulamin" name='chkRegulamin'></input>
            <span className="checkmark" id='checkmarkRegulamin'></span>
          </label>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              <p>ORGANIZACJA:</p>
              <input
                id='inputOrganizacja'
                className='inputOrganizacja'
                type="text"
                value={formData.organizacja}
                onChange={handleChange}
                name='organizacja'
                placeholder='PRYWATNIE'
              />
            </label>
            <div className='footnote-container'>
              <p>*W przypadku prywatnego zgłoszenia prosimy o wpisanie słowa: "PRYWATNIE"</p>
            </div>
          </div>
          <br></br>
          <br></br>

          <div className='osoby-container'>
          <h1>UCZESTNICY</h1>
            {formData.osoby.map((osoba, index) => (
              <div className='osoba-container'>
                <div>
                  <label>
                    <p>IMIĘ:</p>
                    <input
                      id='inputImie'
                      className='inputImie'
                      type="text"
                      value={formData.imie}
                      onChange={(e) => handleOsobaInputChange(e, index)}
                      name='imie'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <p>NAZWISKO:</p>
                    <input
                      id='inputNazwisko'
                      className='inputNazwisko'
                      type="text"
                      value={formData.nazwisko}
                      onChange={(e) => handleOsobaInputChange(e, index)}
                      name='nazwisko'
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <p>PŁEĆ:</p>
                    <select
                      id='inputPlec'
                      className='inputPlec'
                      value={formData.plec}
                      onChange={(e) => handleOsobaInputChange(e, index)}
                      name='plec'>
                      <option value="">--Wybierz płeć--</option>
                      <option value="K">Kobieta</option>
                      <option value="M">Mężczyzna</option>
                    </select>
                  </label>
                </div>
                <div>
                  <label>
                    <p>GRUPA WIEKOWA:</p>
                    <select
                      id='inputGrupaWiekowa'
                      className='inputGrupaWiekowa'
                      value={formData.grupaWiekowa}
                      onChange={(e) => handleOsobaInputChange(e, index)}
                      name='grupaWiekowa'>
                      <option value="">--Wybierz grupę wiekową--</option>
                      <option value="KLASY I-III">KLASY I-III</option>
                      <option value="KLASY IV-VI">KLASY IV-VI</option>
                      <option value="14-16 LAT">14-16 LAT</option>
                      <option value="17-30 LAT">17-30 LAT</option>
                      <option value="31-50 LAT">31-50 LAT</option>
                      <option value="50+">50+</option>
                    </select>
                  </label>
                </div>
                <div className='niepelnosprawnosci-div'>
                  <label className='niepelnosprawnosci-label'>
                    <p>RODZAJ NIEPEŁNOSPRAWNOŚCI WG ORZECZENIA LEKARSKIEGO:</p>
                    <div className='multiple-choice-container multiple-choice-container-rodzaj-niepelnosprawnosci' id='multiple-choice-container-rodzaj-niepelnosprawnosci'>
                      <label>
                        <input type='checkbox' id='INTELEKTUALNA' name='INTELEKTUALNA' value='INTELEKTUALNA' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        <b>*INTELEKTUALNA</b>
                      </label>
                      <label>
                        <input type='checkbox' id='FIZYCZNA' name='FIZYCZNA' value='FIZYCZNA' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        <b>*FIZYCZNA</b>
                      </label>
                      <label>
                        <input type='checkbox' id='SPRZEZONE' name='SPRZEZONE' value='SPRZĘŻONE' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        SPRZĘŻONE
                      </label>
                      <label>
                        <input type='checkbox' id='NIEWIDOMY/SLABOWIDZACY' name='NIEWIDOMY/SLABOWIDZACY' value='NIEWIDOMY/SŁABOWIDZĄCY' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        NIEWIDOMY/SŁABOWIDZĄCY
                      </label>
                      <label>
                        <input type='checkbox' id='NIESŁYSZACY_NIEDOSLYSZACY' name='NIESŁYSZACY_NIEDOSLYSZACY' value='NIESŁYSZĄCY/NIEDOSŁYSZĄCY' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        NIESŁYSZĄCY/NIEDOSŁYSZĄCY
                      </label>
                      <label>
                        <input type='checkbox' id='AUTYZM' name='AUTYZM' value='AUTYZM' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        AUTYZM
                      </label>
                      <label>
                        <input type='checkbox' id='ZABURZENIA_PSYCHICZNE' name='ZABURZENIA_PSYCHICZNE' value='ZABURZENIA_PSYCHICZNE' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        ZABURZENIA PSYCHICZNE
                      </label>
                      <label>
                        <input type='checkbox' id='NIEPELNOSPRAWNOSC_KONCZYN_GORNYCH' name='NIEPELNOSPRAWNOSC_KONCZYN_GORNYCH' value='NIEPEŁNOSPRAWNOŚĆ_KOŃCZYN_GÓRNYCH' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        NIEPEŁNOSPRAWNOŚĆ KOŃCZYN GÓRNYCH
                      </label>
                      <label>
                        <input type='checkbox' id='NIEPELNOSPRAWNOSC_KONCZYN_DOLNYCH' name='NIEPELNOSPRAWNOSC_KONCZYN_DOLNYCH' value='NIEPEŁNOSPRAWNOŚĆ_KOŃCZYN_DOLNYCH' onChange={(e) => handleRodzajNiepelnosprawnosciSelectionChanged(e, index)}></input>
                        NIEPEŁNOSPRAWNOŚĆ KOŃCZYN DOLNYCH
                      </label>
                    </div>
                  </label>
                  <p>*Należy wybrać przynajmniej jeden rodzaj niepełnosprawności oznaczony gwiazdką</p>
                </div>
                <div>
                  <label>
                      <p>STOPIEŃ NIEPEŁNOSPRAWNOŚCI WG ORZECZENIA LEKARSKIEGO:</p>
                      <select 
                      id='inputStopienNiepelnosprawnosci'
                      className='inputStopienNiepelnosprawnosci'
                      value={formData.stopienNiepelnosprawnosci}
                      onChange={(e) => handleOsobaInputChange(e, index)}
                      name='stopienNiepelnosprawnosci'>
                          <option value="">--Wybierz stopień niepełnosprawności--</option>
                          <option value="LEKKI">LEKKI</option>
                          <option value="UMIARKOWANY">UMIARKOWANY</option>
                          <option value="ZNACZNY">ZNACZNY</option>
                      </select>
                  </label>
                </div>
                <div>
                  <label>
                    <p>CZY PORUSZA SIĘ PAN/PANI PRZY POMOCY:</p>
                    <select
                    id='inputPoruszaSiePrzyPomocy'
                    className='inputPoruszaSiePrzyPomocy'
                    value={formData.poruszaSiePrzyPomocy}
                    onChange={(e) => handleOsobaInputChange(e, index)}
                    name='poruszaSiePrzyPomocy'>
                      <option value="">--Wybierz jedną z opcji--</option>
                      <option value="KULE">KULE</option>
                      <option value="WÓZEK_INWALIDZKI">WÓZEK INWALIDZKI</option>
                      <option value="LASKA">LASKA</option>
                      <option value="CHODZIK">CHODZIK</option>
                      <option value="POMOC_INNEJ_OSOBY">POMOC INNEJ OSOBY</option>
                      <option value="BEZ_POMOCY/SAMODZIELNIE/NIE_POTRZEBUJE_POMOCY">BEZ POMOCY/SAMODZIELNIE/NIE POTRZEBUJE POMOCY</option>
                    </select>
                  </label>
                </div>
                <div>
                  <label className='dyscypliny-label'>
                    <p>DYSCYPLINY:</p>
                    <div className='multiple-choice-container multiple-choice-container-dyscypliny' id='multiple-choice-container-dyscypliny'>
                      <label>
                        <input type='checkbox' id='bieg60m' name='bieg60m' value='BIEG_60M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        BIEG 60m (klasy I-III)
                      </label>
                      <label>
                        <input type='checkbox' id='bieg100m' name='bieg100m' value='BIEG_100M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        BIEG 100m
                      </label>
                      <label>
                        <input type='checkbox' id='bieg400m' name='bieg400m' value='BIEG_400M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        BIEG 400m
                      </label>
                      <label>
                        <input type='checkbox' id='wozki100m' name='wozki100m' value='WÓZKI_100M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        WÓZKI 100m (klasy I-III)
                      </label>
                      <label>
                        <input type='checkbox' id='wozkiInwalidzkieZeStartuWspolnego400m' name='wozkiInwalidzkieZeStartuWspolnego400m' value='WÓZKI_INWALIDZKIE_ZE_STARTU_WSPÓLNEGO_400M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        WÓZKI INWALIDZKIE ZE STARTU WSPÓLNEGO 400m
                      </label>
                      <label>
                        <input type='checkbox' id='biegDlaPrzedszkolakowZRodzicem100m' name='biegDlaPrzedszkolakowZRodzicem100m' value='BIEG_DLA_PRZEDSZKOLAKÓW_Z_RODZICEM_100M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        BIEG DLA PRZEDSZKOLAKÓW Z RODZICEM 100m
                      </label>
                      <label>
                        <input type='checkbox' id='skokWDal' name='skokWDal' value='SKOK_W_DAL' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        SKOK W DAL
                      </label>
                      <label>
                        <input type='checkbox' id='rzutPilkaLekarska1kg' name='rzutPilkaLekarska1kg' value='RZUT_PIŁKĄ_LEKARSKĄ_1KG' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        RZUT PIŁKĄ LEKARSKĄ 1kg (klasy I-III)
                      </label>
                      <label>
                        <input type='checkbox' id='rzutPilkaLekarska2kg' name='rzutPilkaLekarska2kg' value='RZUT_PIŁKĄ_LEKARSKĄ_2KG' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        RZUT PIŁKĄ LEKARSKĄ 2kg (klasy IV-VI)
                      </label>
                      <label>
                        <input type='checkbox' id='rzutPilkaLekarska3kg' name='rzutPilkaLekarska3kg' value='RZUT_PIŁKĄ_LEKARSKĄ_3KG' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        RZUT PIŁKĄ LEKARSKĄ 3kg (pozostali)
                      </label>
                      <label>
                        <input type='checkbox' id='wiatrowka' name='wiatrowka' value='WIATRÓWKA' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        WIATRÓWKA
                      </label>
                      <label>
                        <input type='checkbox' id='wioslarstwo' name='wioslarstwo' value='WIOŚLARSTWO' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        WIOŚLARSTWO
                      </label>
                      <label>
                        <input type='checkbox' id='rowerStacjonarny' name='rowerStacjonarny' value='ROWER_STACJONARNY' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        ROWER STACJONARNY
                      </label>
                      <label id='labelPilkaNozna'>
                        <input type='checkbox' id='pilkaNozna' name='pilkaNozna' value='PIŁKA_NOŻNA' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        PIŁKA NOŻNA
                      </label>
                      <label>
                        <input type='checkbox' id='zestawKonkurencji' name='zestawKonkurencji' value='ZESTAW_KONKURENCJI' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        ZESTAW KONKURENCJI
                      </label>
                      <label>
                        <input type='checkbox' id='boule' name='boule' value='BOULE' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        BOULE
                      </label>
                      <label>
                        <input type='checkbox' id='nordicWalking' name='nordicWalking' value='NORDIC_WALKING' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        NORDIC WALKING
                      </label>
                      <label>
                        <input type='checkbox' id='tenisStolowy' name='tenisStolowy' value='TENIS_STOŁOWY' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        TENIS STOŁOWY (turniej)
                      </label>
                      <label>
                        <input type='checkbox' id='wyciskanieSztangi' name='wyciskanieSztangi' value='WYCISKANIE_SZTANGI' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        WYCISKANIE SZTANGI
                      </label>
                      <label>
                        <input type='checkbox' id='wspinaczka' name='wspinaczka' value='WSPINACZKA' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        WSPINACZKA
                      </label>
                      <label id='labelPlywanie25'>
                        <input type='checkbox' id='plywanie25m' name='plywanie25m' value='PŁYWANIE_25M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        PŁYWANIE 25m
                      </label>
                      <label id='labelPlywanie50'>
                        <input type='checkbox' id='plywanie50m' name='plywanie50m' value='PŁYWANIE_50M' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        PŁYWANIE 50m
                      </label>
                      <label id='labelZabawyWWodzie'>
                        <input type='checkbox' id='zabawyWWodzie' name='zabawyWWodzie' value='ZABAWY_W_WODZIE' onChange={(e) => handleDyscyplinySelectionChanged(e, index)}></input>
                        ZABAWY W WODZIE
                      </label>
                    </div>
                  </label>
                </div>
                <div className='warning-container'>
                  <p className='pWarning' id='pWarning'>*W przypadku Piłki nożnej oraz Basenu (Pływanie 25/50m oraz Zabawy w wodzie) możliwe jest wybranie tylko jednej z dyscyplin</p>
                  <br></br>
                </div>
                <button onClick={(e) => handleRemoveOsoba(e, index)}>Usuń</button>
                <hr></hr>
              </div>
            ))}
            <button type='' onClick={handleAddOsoba}>Dodaj uczestnika</button>
          </div>


          <div className='opiekunowie-container'>
          <h1>OPIEKUNOWIE</h1>
            {formData.opiekunowie.map((opiekun, index) => (
              <div key={index} className='opiekun-container'>
                <label>
                  <p>IMIĘ OPIEKUNA:</p>
                  <input
                    type="text"
                    name="imie"
                    value={opiekun.imie}
                    onChange={(e) => handleOpiekunInputChange(e, index)}
                  />
                </label>
                <label>
                  <p>NAZWISKO OPIEKUNA:</p>
                  <input
                    type="text"
                    name="nazwisko"
                    value={opiekun.nazwisko}
                    onChange={(e) => handleOpiekunInputChange(e, index)}
                  />
                </label>
                <label>
                  <p>TELEFON KOMÓRKOWY OPIEKUNA:</p>
                  <input
                    type="text"
                    name="numerTelefonu"
                    value={opiekun.numerTelefonu}
                    onChange={(e) => handleOpiekunInputChange(e, index)}
                  />
                </label>
                <button onClick={(e) => handleRemoveOpiekun(e, index)}>Usuń</button>
                <hr></hr>
              </div>
            ))}
            <button type='' onClick={handleAddOpiekun}>Dodaj opiekuna</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <div className='button-wyslij-container'>
            <button type="submit" id="btnWyslij" name='btnWyslij'>Wyślij</button>
          </div>
        </form>
    </div>
  )
}

export default FormularzZgloszeniowy
