import React, { useState, useEffect } from 'react';
import './Countdown.css'

function Countdown({ targetDate }) {
    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const distance = targetDate - now;

        if (distance <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0, isFinished: true };
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds, isFinished: false };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className='countdown-container'>
            {timeLeft.isFinished ? (
                <div><p>Czas minął!</p></div>
            ) : (
                <div>
                    <p>Pozostało: {timeLeft.days} dni {timeLeft.hours} godzin {timeLeft.minutes} minut {timeLeft.seconds} sekund</p>
                </div>
            )}
        </div>
    );
}

export default Countdown;