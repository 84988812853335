import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footer-container'>
      <p>Masz pytania? Zadzwoń <a href='tel:606612583'>606-612-583</a> Seweryn Smorga</p>
    </div>
  )
}
