import React from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <nav className='navbar'>
      <Link to='/'>Strona główna</Link>
      <Link to='/pages/Harmonogram/Harmonogram.js'>Harmonogram</Link>
      <Link to='./pages/Kontakt/Kontakt.js'>Kontakt</Link>
      <Link to='./pages/Wolontariat/Wolontariat.js'>Wolontariat</Link>
      <Link to='./pages/Wsparcie/Wsparcie.js'>Wsparcie</Link>
      <Link to='/pages/Formularz/FormularzZgloszeniowy.js'>Formularz zgłoszeniowy</Link>
    </nav>
  )
}

export default NavBar
