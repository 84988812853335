import React from 'react'
import './Article.css'

function Article({header, paragraph}) {
  return (
    <div className='article-container'>
        <h1 className='header'>{header}</h1>
        <p className='paragraph'>{paragraph}</p>
    </div>
  )
}

export default Article
